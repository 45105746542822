import AuthManager from '@kfc-global/react-shared/auth/AuthManager';
import { LOGIN_PATH_NAME } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { ROUTE_URL, LOCAL_STORAGE_KEYS } from 'common/constants/SharedConstants';
import events from '@kfc-global/react-shared/analytics/Register';
import { getDataLayer, isSupportedTenantCode } from 'common/utilities/utils';
import { logEvent } from 'common/utilities/analyticsUtils';

const { getInstance: auth } = AuthManager;

export const getPathName = () => {
  const { HOME_PAGE, SIGNOUT } = ROUTE_URL;
  const pathName = window.location.pathname;
  if (pathName === SIGNOUT) {
    return HOME_PAGE;
  }
  return pathName;
};

export const login = () => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALIZATION_SIGN_IN, JSON.stringify(true));
  const redirectUri = window.location.origin + config(LOGIN_PATH_NAME);
  const signInUrl = auth().generateSignInUrl(redirectUri, false, getPathName());
  logEvent(events.signInStart?.name, {
    '%event%': 'signInStart',
    '%loginMethod%': isSupportedTenantCode() ? 'email' : 'phone',
  });
  window.location.assign(signInUrl);
};

// Uncomment #27 post implementation
// istanbul ignore next
export const createAccount = customerDetails => {
  const redirectUri = window.location.origin + config(LOGIN_PATH_NAME);
  let signUpUrl = auth().generateSignInUrl(redirectUri, true, getPathName());
  if (customerDetails) {
    signUpUrl = `${signUpUrl}&${customerDetails}`;
  }
  window.location.assign(signUpUrl);
};

export const setAnalyticsRegistrationFinish = (isNewCustomer, loginMethod = 'Phone') => {
  const {
    phoneNumber,
    customerId,
    firstName,
    familyName: lastName,
    email,
    emailSubscription,
    phoneSubscription,
  } = auth();
  if (customerId && isNewCustomer) {
    logEvent(
      events.registrationFinish?.name,
      {
        '%event%': 'registrationFinish',
        '%loginMethod%': loginMethod || 'NA',
        '%userID%': customerId,
        '%firstName%': firstName || 'NA',
        '%lastName%': lastName || 'NA',
        '%emailAddress%': email || 'NA',
        '%phoneNumber%': phoneNumber || 'NA',
        '%marketingEmails%': emailSubscription ? 'Yes' : 'No',
        '%receiveSMS%': phoneSubscription ? 'Yes' : 'No',
      },
      getDataLayer,
    );
  }
};

export const setAnalyticsSignInSkipped = () => {
  const { customerId } = auth();
  logEvent(
    events.signInFinish?.name,
    {
      '%event%': 'signInFinish',
      '%userID%': customerId || 'NA',
      '%loginMethod%': 'I’m a guest, sign me up later!',
      '%firstName%': 'NA',
      '%lastName%': 'NA',
      '%phoneNumber%': 'NA',
    },
    getDataLayer,
  );
};

export const setAnalyticsSignInFinish = (loginMethod = 'Phone') => {
  const { phoneNumber, customerId, firstName, familyName: lastName, email } = auth();
  logEvent(
    events.signInFinish?.name,
    {
      '%event%': 'signInFinish',
      '%userID%': customerId || 'NA',
      '%loginMethod%': loginMethod || 'NA',
      '%firstName%': firstName || 'NA',
      '%lastName%': lastName || 'NA',
      '%phoneNumber%': phoneNumber || 'NA',
      '%emailAddress%': email || 'NA',
    },
    getDataLayer,
  );
};
