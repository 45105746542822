import { parseInvalidDate, parseInvalidDateAndFormat } from 'common/utilities/dayJsUtils';
import SEARCH_STORE_CONSTANTS from '../Constants/SearchStoreConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import events from '@kfc-global/react-shared/analytics/Register';
import {
  getAndCheck,
  getDataLayer,
  getEmbeddedErrorMessageAnalytic,
  getFullAddressDetails,
} from 'common/utilities/utils';
import { ChannelManager } from 'common/manager';
import SEARCHSTORE_MODULE_DATA from '../Data/Defaults';
import { parseAddress } from '@kfc-global/react-shared/utils/AddressParser.js';
import { TENANT_DELIVERY_ADDRESS_MAPPING } from 'common/constants/SharedConstants';

const {
  TAKEAWAY_UNAVAILABLE_WITH_CTA,
  TAKEAWAY_UNAVAILABLE,
  DINEIN_UNAVAILABLE,
  TAKE_AWAY_SERVICE,
  DINE_IN_SERVICE,
  STORE_STATUS_OPEN,
  DELIVERY_SERVICE,
  DELIVERY_UNAVAILABLE,
  LOCATION_WARNING_MESSAGE,
} = SEARCH_STORE_CONSTANTS;

let storeStatusData = [],
  eligibleStores = [],
  storeStatusCallBack,
  allPromise = [];

/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = async analyticsData => {
  const analyticsItems = [];
  analyticsItems.push(analyticsData);
  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};
export const setLocalisedSignInAnalyticsData = async () => {
  await AnalyticsService.registerEvent(
    events.localisationSignIn.name,
    {
      '%event%': 'localisationSignIn',
    },
    getDataLayer,
  );
};
export const setOrderNowAnalyticsData = async () => {
  await AnalyticsService.registerEvent(
    events.orderNow.name,
    {
      '%event%': 'orderNow',
    },
    getDataLayer,
  );
};
export const setOrderLaterAnalyticsData = async () => {
  const analyticsItems = [];
  analyticsItems.push({
    event: 'orderForLater',
  });
  await AnalyticsService.registerEvent(
    undefined,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};
export const localisationViewAnalyticsData = async (showMap, dispositionType) => {
  await AnalyticsService.registerEvent(
    events.localisationViewPWA?.name,
    {
      '%event%': 'localisationView',
      '%viewSelected%': showMap ? translateWithI18Next('list') : translateWithI18Next('map'),
      '%orderType%': dispositionType,
    },
    getDataLayer,
  );
};
export const setUseMyLocationAnalytics = async () => {
  await AnalyticsService.registerEvent(
    events.useMyLocation?.name,
    {
      data: {
        event: 'useMyLocation',
      },
    },
    getDataLayer,
  );
};
/**
 * Method to call end points depending on number of geo hash values
 * @param {*} allEligibleStoreData
 */
export const getStoreStatusData = (allEligibleStoreData, url, tenantId, tenantSessionId, tenantRequestId, cb) => {
  storeStatusData = [];
  eligibleStores = allEligibleStoreData;
  storeStatusCallBack = cb;
  if (eligibleStores.length === 0) {
    storeStatusCallBack([]);
  }
  const requests = eligibleStores.map(item => {
    const myUrl = `${url.replace('{storeid}', item.id || item.storeBasic.id)}`;
    return fetch(myUrl, {
      headers: {
        'Content-type': 'application/json',
        'x-tenant-id': tenantId,
        'x-correlation-session-id': tenantSessionId,
        'x-correlation-request-id': tenantRequestId,
      },
    });
  });

  Promise.allSettled(requests)
    .then(responses => {
      allPromise = [];
      responses.forEach((response, index, array) => {
        if (response.status === SEARCH_STORE_CONSTANTS.FULFILLED_TEXT) {
          addAllData(response.value.json(), index === array.length - 1);
        }
      });
    })
    .catch(err => console.log(err));
};
/**
 * Method to populate all stored data from end point
 * @param {*} promise for all fetch requests
 */
const addAllData = (promise, isLastItem) => {
  allPromise.push(promise);
  isLastItem &&
    Promise.all(allPromise).then(responseArr => {
      responseArr.forEach(data => {
        storeStatusData.push({ summary: { ...data }, module: data });
      });
      mapStoreStatusToStoreObject();
    });
};

/**
 * Method to map store status data with egilible store objects
 */
const mapStoreStatusToStoreObject = () => {
  const storeStatusMappedStoreObjects = eligibleStores.map((item, index) => {
    const module = {
      id: item?.id ?? null,
      tenders: storeStatusData?.[index]?.module?.availableTenders ?? null,
    };

    return { ...item, ...storeStatusData[index], module };
  });
  storeStatusCallBack(storeStatusMappedStoreObjects);
};
// method to return array of channels for web
export const getChannels = availableChannels => {
  return availableChannels.filter(obj => obj.channel === ChannelManager.channel);
};

// method to check today's day
export const checkTodayDay = (isOpen, operatingHours) => {
  let storeTimeMsg = '';
  let dayName = '';
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'EVERYDAY'];
  const newDate = new Date();
  if (isOpen) {
    dayName = days[newDate.getDay()].toUpperCase();
    operatingHours.length &&
      operatingHours.filter(obj => {
        if (obj.day.toUpperCase() === dayName.toUpperCase()) {
          storeTimeMsg =
            parseInvalidDate(obj.availableHours.to, 'hhmm')?.minute() === 0
              ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'hhA',
                )
                  ?.split(' ')
                  .join('')}`
              : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'hh:mmA',
                )
                  ?.split(' ')
                  .join('')}`;
        } else if (obj.day.toUpperCase() === SEARCH_STORE_CONSTANTS.EVERYDAY) {
          storeTimeMsg =
            parseInvalidDate(obj.availableHours.to, 'hhmm')?.minute() === 0
              ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'hhA',
                )
                  ?.split(' ')
                  .join('')}`
              : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_UNTIL)} ${parseInvalidDateAndFormat(
                  obj.availableHours.to,
                  'hhmm',
                  'hh:mmA',
                )
                  ?.split(' ')
                  .join('')}`;
        }
      });
  } else {
    if (newDate.getDay() + 1 >= days.length) {
      dayName = days[newDate.getDay() + 1 - days.length].toUpperCase();
    } else {
      dayName = days[newDate.getDay() + 1].toUpperCase();
    }
    operatingHours.filter(obj => {
      if (obj.day.toUpperCase() === dayName.toUpperCase()) {
        storeTimeMsg =
          parseInvalidDate(obj.availableHours.from, 'hhmm')?.minute() === 0
            ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'hhA',
              )
                ?.split(' ')
                .join('')}`
            : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'hh:mmA',
              )
                ?.split(' ')
                .join('')}`;
      } else if (obj.day.toUpperCase() === SEARCH_STORE_CONSTANTS.EVERYDAY) {
        storeTimeMsg =
          parseInvalidDate(obj.availableHours.from, 'hhmm')?.minute() === 0
            ? `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'hhA',
              )
                ?.split(' ')
                .join('')}`
            : `${translateWithI18Next(SEARCH_STORE_CONSTANTS.OPENS_TOMORROW)} ${parseInvalidDateAndFormat(
                obj.availableHours.from,
                'hhmm',
                'hh:mmA',
              )
                ?.split(' ')
                .join('')}`;
      }
    });
  }

  return storeTimeMsg;
};
/**
 * Method to get address by passing lat long values
 */
export const getAddressByGeoCode = latlng => {
  const deliveryAddressMapping = JSON.parse(localStorage.getItem(TENANT_DELIVERY_ADDRESS_MAPPING));
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const filterAddress = results.filter(e => !e.formatted_address.includes('+'));
          let formattedAddress = parseAddress(filterAddress[0]?.address_components, deliveryAddressMapping);
          resolve(getFullAddressDetails(formattedAddress));
        } else {
          console.log('No results found');
          reject(results);
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
        reject(results);
      }
      resolve(results[0].formatted_address);
    });
  });
};

export const callUtilFunct = (dispositionType, eligibleStoreDataList) => {
  const serviceArray = [];
  const closedStores = [];
  let checkForWEBChannel;
  let DISPOSITION_TYPE_SERVICE;
  let errorMsg;
  let errorMsgWithCTA;

  switch (dispositionType.toLowerCase()) {
    case 'delivery':
      DISPOSITION_TYPE_SERVICE = DELIVERY_SERVICE;
      errorMsg = translateWithI18Next(DELIVERY_UNAVAILABLE);
      errorMsgWithCTA = translateWithI18Next(DELIVERY_UNAVAILABLE);
      break;
    case 'takeaway':
      errorMsg = translateWithI18Next(TAKEAWAY_UNAVAILABLE);
      DISPOSITION_TYPE_SERVICE = translateWithI18Next(TAKE_AWAY_SERVICE);
      errorMsgWithCTA = translateWithI18Next(TAKEAWAY_UNAVAILABLE_WITH_CTA);
      break;
    case 'dine-in':
      DISPOSITION_TYPE_SERVICE = DINE_IN_SERVICE;
      errorMsg = translateWithI18Next(DINEIN_UNAVAILABLE);
      errorMsgWithCTA = translateWithI18Next(DINEIN_UNAVAILABLE);
      break;
  }

  eligibleStoreDataList?.map((item, index) => {
    let isOpenSTORE = item?.summary?.status?.currentStatus !== STORE_STATUS_OPEN;
    isOpenSTORE && closedStores.push(item);
    if (item?.availableServicesPerChannel) {
      checkForWEBChannel = item?.availableServicesPerChannel?.filter(
        ({ channel }) => channel === ChannelManager.channel,
      );
      let el = checkForWEBChannel?.length ? checkForWEBChannel[0]?.services : [];
      const notDispositionService = !el.includes(DISPOSITION_TYPE_SERVICE);
      notDispositionService && el.length && serviceArray.push(item);
    }
  });

  return eligibleStoreDataList?.length === serviceArray?.length
    ? errorMsg
    : !checkForWEBChannel?.length || eligibleStoreDataList?.length === closedStores?.length
    ? errorMsgWithCTA
    : null;
};

export const compareDname = (storeOne, storeTwo) => {
  if (storeOne.name[0].value < storeTwo.name[0].value) {
    return -1;
  }
  if (storeOne.name[0].value > storeTwo.name[0].value) {
    return 1;
  }
  return 0;
};

export const deliveryModuleAnalyticFn = (locationAlert, negativeScenarioAlert) => {
  if (getAndCheck(locationAlert, !negativeScenarioAlert)) {
    getEmbeddedErrorMessageAnalytic(
      translateWithI18Next(SEARCHSTORE_MODULE_DATA.contentData.deliveryHeaderText),
      translateWithI18Next(LOCATION_WARNING_MESSAGE),
    );
  }
};

export const checkAddressPresentInLocalStorage = (retainAddress, setSuggestedAddress, handleSelectAddress) => {
  if (retainAddress) {
    setSuggestedAddress([{ description: retainAddress }]);
    handleSelectAddress(retainAddress, {}, {});
  }
};
